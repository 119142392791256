const { OPEN_WEATHER_MAP, SPACETI } = require('../../assets/creds.json')
const axios = require('axios');

// axios.interceptors.request.use((request: any) => {
//     console.log('Starting Request', JSON.stringify(request, null, 2))
//     return request
//   })
  

const restCall = async (url: string, bodyParams: any | null = null, headers: any | null = null) => {
    const res = await axios.get(url, { data: bodyParams, headers: headers });
    
    try {
        return res.data;
    }
    catch(err) {
        console.log('Error: ', err.message);
    }
}

export const getWeatherInfo = async (lat: number, lon: number, units="metric") => {
    // const API_URL = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&units=${units}&appid=${OPEN_WEATHER_MAP.API_KEY}`;
    const API_URL = `https://dt.r2m.cloud/api/getWeatherInfo?lat=${lat}&lon=${lon}&units=${units}&appid=${OPEN_WEATHER_MAP.API_KEY}`;

    const responseData = await restCall(API_URL);
    
    console.log('OpenWeatherData', responseData.main)
    
    return Math.round(responseData.main.temp)
}

export const getDeviceOccupancy = async (mac: string) => {
    // const API_URL = `https://api.spaceti.net/v2/devices/${mac}`;
    const API_URL = `https://dt.r2m.cloud/api/getDevice/${mac}`;

    const responseData = await restCall(API_URL, {}, {Authorization: `Bearer ${SPACETI.API_KEY}`});
    
    console.log('getDeviceOccupancy', mac, responseData.occupancy.measurement.value)
    
    return responseData.occupancy.measurement.value
}

export const getDeviceTempCO2 = async (mac: string) => {
    // const API_URL = `https://api.spaceti.net/v2/devices/${mac}`;
    const API_URL = `https://dt.r2m.cloud/api/getDevice/${mac}`;

    const responseData = await restCall(API_URL, {}, {Authorization: `Bearer ${SPACETI.API_KEY}`});
    
    const tempCO2 = (({ co2, temperature }) => ({ co2: co2.measurement.value, temperature: temperature.measurement.value }))(responseData)

    console.log('getDeviceTempCO2', mac, tempCO2)
    
    return tempCO2
}

export const getDevices = async () => {
    // const API_URL = `https://api.spaceti.net/v2/devices`;
    const API_URL = `https://dt.r2m.cloud/api/getDevices`;

    const responseData = await restCall(API_URL, {}, {Authorization: `Bearer ${SPACETI.API_KEY}`});
    
    console.log('getDevices', responseData.records)
    
    return responseData.records
}


export const getEvents = async () => {
    // const API_URL = `http://localhost:8081/getEvents`;
    const API_URL = `https://dt.r2m.cloud/api/getEvents`;

    const responseData = await restCall(API_URL);
    
    console.log('Events', responseData)
    
    return responseData
}

export const getSentimentStats = async () => {
    // const API_URL = `http://localhost:8081/getSentimentStats`;
    const API_URL = `https://dt.r2m.cloud/api/getSentimentStats`;

    const responseData = await restCall(API_URL);
    
    console.log('SentimentStats', responseData)
    
    return responseData
}

export const getTrainDepartures = async () => {
    // const API_URL = `http://localhost:8081/getTrainDepartures`;
    const API_URL = `https://dt.r2m.cloud/api/getTrainDepartures`;

    const responseData = await restCall(API_URL);
    
    console.log('SentimentStats', responseData)
    
    return responseData
}

export const getUsers = async () => {
    // const API_URL = `http://localhost:8081/getUsersFromDB`;
    const API_URL = `https://dt.r2m.cloud/api/getUsersFromDB`;

    const responseData = await restCall(API_URL);
    
    console.log('Users from DB', responseData)
    
    return responseData
}

export const listFiles = async () => {
    // const API_URL = `http://localhost:8081/listFiles`;
    const API_URL = `https://dt.r2m.cloud/api/listFiles`;

    const responseData = await restCall(API_URL);
    
    console.log('Files', responseData)
    
    return responseData
}

// http://localhost:8081/getR2MStats?year=2022&euLevel=false&country=IT&checkType=true
export const getR2MStats = async (year: string = '2022', euLevel: boolean = false, country: string = 'IT', checkType: boolean = true) => {
    const API_URL = `https://dt.r2m.cloud/api/getR2MStats?year=${year}&euLevel=${euLevel}&country=${country}&checkType=${checkType}`;

    const responseData = await restCall(API_URL);
    
    console.log('Files', responseData)
    
    return responseData
}