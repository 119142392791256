import { SceneComponent, ComponentOutput, Size, IPainter2d } from '@mp/common';

type Inputs = {
  columns: string[];
  cellWidth: number;
  cellHeight: number;
  font: string;
  headerFont: string;
  size: Size;
  title: string;
};

type Outputs = {
  painter: IPainter2d | null;
} & ComponentOutput;

class CanvasTable extends SceneComponent implements IPainter2d {
  inputs: Inputs = {
    font: 'normal bold 80px sans-serif',
    headerFont: 'normal bold 80px sans-serif',
    columns: [],
    cellWidth: 300,
    cellHeight: 40,
    size: { h: 100, w: 100 },
    title: 'Placeholder'
  }

  outputs = {
    painter: null,
  } as Outputs;

  private data: any | null = null;
  private functionArguments: Array<any> | null = [];
  private updateFunction: Function = async () => {};

  private bodyLineHeight = 17;
  private headerLineHeight = 25;

  private horizontalMargin = 15;
  private verticalMargin = 10;

  private tableStartY = 20;

  private xOffset = 0.5 + this.horizontalMargin;
  private verticalMarginOffset = this.verticalMargin + this.tableStartY;

  private yOffset = 0.5 + this.verticalMarginOffset;
  private xOffsetContent = this.xOffset + 5;

  private bh = 40;
  private bw = 200;

  constructor(updateFunction: Function | null = null, functionArguments: Array<any> | null = []) {
    super();

    if(updateFunction) this.updateFunction = updateFunction;
    if(functionArguments) this.functionArguments = functionArguments;
  }

  async onInit() {
    this.data = await this.updateFunction(...this.functionArguments);

    // const responseData = await this.updateFunction(...this.functionArguments);
    // this.data = responseData.records.map( (record: any) => ({
    //   timestamp: record.timestamp,
    //   observerMac: record.observer.mac,
    //   eventData: record.event.data.value, 
    //   eventType: record.event.data.type, 
    //   location: `${record.event.geo.location[0].toFixed(10)}, ${record.event.geo.location[1].toFixed(10)}`
    // }));

    this.bh = (this.data.length + 1) * this.inputs.cellHeight; // Calculating Border Height
    this.bw = (this.inputs.columns.length) * this.inputs.cellWidth; //Calculating Border Width
  
    this.outputs.painter = this;
  }

  onInputsUpdated() {
    this.notify('paint.ready');
  }

  async paint(context2d: CanvasRenderingContext2D, size: Size): Promise<void> {
    if(!this.data) return;

    console.log('this.data', this.data);

    //Setting properties for the border lines in the table drawn
    context2d.strokeStyle = "black";
    context2d.font = this.inputs.font;
    context2d.fillStyle = "black";

    //Drawing rows outline on the table
    for (var cellStart = 0; cellStart <= this.bw; cellStart += this.inputs.cellWidth) {
      context2d.moveTo(cellStart + this.xOffset, this.verticalMarginOffset);
      context2d.lineTo(cellStart + this.xOffset, this.bh + this.verticalMarginOffset);
    }

    //Drawing column outline on the table
    for (var cellStart = 0; cellStart <= this.bh; cellStart += this.inputs.cellHeight) {
      context2d.moveTo(this.horizontalMargin, cellStart + this.yOffset);
      context2d.lineTo(this.bw + this.horizontalMargin, cellStart + this.yOffset);
    }
    
    context2d.stroke();

    // Drawing table body
    let rowStart = this.inputs.cellHeight * 2 + this.bodyLineHeight; // Doubling cellHeight because of the header row
    for (let row of this.data){
      let cellStart = 0;
      for (let column of this.inputs.columns){
        context2d.fillText(row[column] || typeof(row[column]) === 'boolean' ? row[column] : "", cellStart + this.xOffsetContent, rowStart);
        cellStart += this.inputs.cellWidth;
      }
      rowStart += this.inputs.cellHeight;
    }

    context2d.font = this.inputs.headerFont;
    
    // Drawing title
    context2d.fillText(this.inputs.title, this.horizontalMargin, this.verticalMargin*2);

    // Drawing table header
    let headerCellStart = 0;
    for (let column of this.inputs.columns) {
      context2d.fillText(column, headerCellStart + this.xOffsetContent, this.verticalMarginOffset + this.headerLineHeight);
      headerCellStart += this.inputs.cellWidth;
    }

    // this.notify('paint.ready');
  }
}

export const canvasTableType = 'mp.canvasTable';
export function makeCanvasTable(updateFunction: Function | null = null, functionArguments: any[] | null = []) {
  return new CanvasTable(updateFunction, functionArguments);
}
