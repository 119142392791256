import { SceneComponent, ComponentOutput, Size, IPainter2d } from '@mp/common';

type Inputs = {
  enabled: boolean,
  label: string,
  position: { x: number; y: number; },
  size: Size;
  radius: number;
  font: string;
  textWidth: number;
  values: any[];
};

type Outputs = {
  negatedValue: boolean;
  painter: IPainter2d | null;
  value: string | null;
} & ComponentOutput;

enum Event {
  Next = 'next',
};

type Events = {
  [Event.Next]: boolean,
};

class CanvasCombo extends SceneComponent implements IPainter2d {
  private valueIndex = 0;

  inputs: Inputs = {
    enabled: true,
    label: "placeholder",
    position: { x: 0, y: 0 },
    size: { h: 100, w: 100 },
    radius: 1,
    font: 'normal bold 80px sans-serif',
    textWidth: 100,
    values: []
  }

  outputs = {
    negatedValue: true,
    painter: null,
    value: null,
  } as Outputs;

  events: Events = {
    [Event.Next]: true,
  };

  onInit() {
    this.outputs.painter = this;
    this.outputs.value = this.inputs.values[0].value;
  }

  onInputsUpdated() {
    this.notify('paint.ready');
  }

  onEvent(eventType: string, eventData: unknown) {
    if (eventType === Event.Next) {
      this.valueIndex++;
      if (this.valueIndex >= this.inputs.values.length) {
        this.valueIndex = 0;
      }

      if (this.inputs.values.length > 0) {
        const currentValue = this.inputs.values[this.valueIndex].value

        this.outputs.value = currentValue;
        this.outputs.negatedValue = !currentValue;

        
        this.notify("paint.ready");
        this.notify("comboUpdated", {[this.inputs.label]: currentValue});
      }
    }
  }

  paint(context2d: CanvasRenderingContext2D, size: Size): void {
    context2d.strokeStyle = 'black';
    context2d.fillStyle = 'black';
    context2d.font = this.inputs.font;
    context2d.textAlign = 'left';
    context2d.textBaseline = 'top';
    wrapText(context2d, this.inputs.enabled ? this.inputs.values[this.valueIndex].key : "", this.inputs.position.x, this.inputs.position.y, this.inputs.textWidth, 30);
  }
}

function wrapText(context: CanvasRenderingContext2D, text: string, x: number, y: number,
  maxWidth: number, lineHeight: number) {
  var words = text.toString().split(' ');
  var line = '';

  for(var n = 0; n < words.length; n++) {
    var testLine = line + words[n] + ' ';
    var metrics = context.measureText(testLine);
    var testWidth = metrics.width;
    if (testWidth > maxWidth && n > 0) {
      context.fillText(line, x, y);
      line = words[n] + ' ';
      y += lineHeight;
    }
    else {
      line = testLine;
    }
  }
  context.fillText(line, x, y);
}

export const canvasComboType = 'mp.canvasCombo';
export function makeCanvasCombo() {
  return new CanvasCombo();
}
