import { SceneComponent, ComponentOutput } from '../SceneComponent';
import { Object3D, AnimationMixer, AnimationAction, LoopOnce, AnimationClip, Mesh, Texture, MeshLambertMaterial, LineSegments } from 'three';
import { IPainter2d } from './CanvasRenderer';
import { PlaneRenderer, Size } from './PlaneRenderer';

const HoverEvent = 'hover';
const UnhoverEvent = 'unhover';
const RepaintEvent = 'repaint';

type Inputs = {
  loadingState: string;
  texture: Texture | null;
  updateInterval: number;
  mac: string;
}

type Outputs = {
  painter: IPainter2d | null;
  visible: boolean;
} & ComponentOutput;


class OccupancyIndicator extends SceneComponent implements IPainter2d {
  private daeComponent: SceneComponent;
  private mixer: AnimationMixer | null = null;
  private onEnterClip: AnimationClip | null = null;
  private mesh: Mesh | null = null;
  private currentTime: number = 0;
  private nextUpdate: number = 0;
  private occupancy: number = 0;

  /**** ADDED CODE ****/
  private updateFunction: Function = async () => {};
  private functionArguments: Array<any> | null = [];

  constructor(updateFunction: Function | null = null, functionArguments: Array<any> | null = []) {
    super();

    if(updateFunction) this.updateFunction = updateFunction;
    if(functionArguments) this.functionArguments = functionArguments;
  }
  /**** /ADDED CODE ****/

  inputs: Inputs = {
    loadingState: 'Idle',
    texture: null,
    updateInterval: 10000,
    mac: ""
  }

  outputs = {
    painter: null,
    visible: false,
  } as Outputs;

  events = {
    [HoverEvent]: true,
    [UnhoverEvent]: true,
  };

  onInit() {
    const root = this.context.root;
    const THREE = this.context.three;

    let planeRenderer: PlaneRenderer;
    for (const component of root.componentIterator()) {
      if (component.componentType === 'mp.daeLoader') {
        this.daeComponent = component;
      }
      else if (component.componentType === 'mp.planeRenderer') {
        planeRenderer = component as PlaneRenderer;
        planeRenderer.outputs.objectRoot.translateZ(0.05);
        planeRenderer.outputs.objectRoot.translateY(0.4);
        planeRenderer.outputs.objectRoot.scale.set(0.5, 0.5, 0.5);
      }
    }


    this.outputs.painter = this;

    this.mixer = new THREE.AnimationMixer(planeRenderer.outputs.objectRoot);

    const tm = 0.2;
    const positionTrack = new THREE.VectorKeyframeTrack('.scale', [0, tm], [
      0, 0, 0,
      0.5, 0.5, 0.5
    ], THREE.InterpolateSmooth);
    this.onEnterClip = new THREE.AnimationClip(null, tm, [positionTrack]);
  }

  onInputsUpdated() {
    const THREE = this.context.three;
    if (this.inputs.loadingState === 'Loaded') {
      const lines: LineSegments[] = [];
      this.daeComponent.outputs.objectRoot.traverse((obj: Object3D) => {
        // we dont want line segments, track them and remove them.
        if (obj.type === 'LineSegments') {
          lines.push(obj as LineSegments);
        }
        else if (obj.type === 'Mesh') {
          this.mesh = obj as Mesh;

          const material = this.mesh.material as MeshLambertMaterial;
          if (material && material.name === '_5b76dbe388862300126c1e14') {
            const newMaterial = new THREE.MeshBasicMaterial({ map: this.inputs.texture });
            this.mesh.material = newMaterial;
          }
        }
      });

      // remove the line segments.
      lines.forEach((line: LineSegments) => {
        line.parent.remove(line);
      });
    }
  }

  onEvent(eventType: string, eventData: unknown): void {
    if (eventType === HoverEvent) {
      const data: any = eventData;
      if (data.hover) {
        this.outputs.visible = true;
        const onEnterAction: AnimationAction = this.mixer.clipAction(this.onEnterClip);
        onEnterAction.stop();
        onEnterAction.loop = LoopOnce;
        onEnterAction.clampWhenFinished = true;
        onEnterAction.play();
      }
      else {
        this.outputs.visible = false;
      }
    }
  }

  paint(context2d: CanvasRenderingContext2D, size: Size): void {
    const x = 490;
    const y = 490;

    context2d.fillStyle = 'black';
    context2d.beginPath();
    context2d.arc(x, y, 400, 0, Math.PI * 2);
    context2d.fill();

    context2d.fillStyle = this.occupancy === 0 ? 'green' : 'red';
    context2d.beginPath();
    context2d.arc(x, y, 300, 0, Math.PI * 2);
    context2d.fill();
  }

  async onTick(delta: number) {
    this.currentTime += delta;

    if (this.mixer) {
      this.mixer.update(delta/1000);
    }

    if (this.currentTime > this.nextUpdate) {
      this.nextUpdate += this.inputs.updateInterval;

      /**** ADDED CODE ****/
      this.occupancy = await this.updateFunction(this.inputs.mac, ...this.functionArguments);
      /**** /ADDED CODE ****/

      this.notify(RepaintEvent);
    }
  }
}

export const occupancyIndicatorType = 'mp.occupancyIndicator';
export const makeOccupancyIndicator = function(updateFunction: Function | null = null, functionArguments: any[] | null = []) {
  return new OccupancyIndicator(updateFunction, functionArguments);
}
