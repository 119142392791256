import { SceneComponent, ComponentOutput, Size, IPainter2d } from '@mp/common';

type Inputs = {
  apiName: string,
  apiParams: string[],
  defaultParams: any,
  position: { x: number; y: number; },
  size: Size;
  radius: number;
  font: string;
  text: string;
  textWidth: number;
};

type Outputs = {
  painter: IPainter2d | null;
  value: string | null;
} & ComponentOutput;

enum Event {
  Submit = 'submit',
  UpdateForm = 'updateForm'
};

type Events = {
  [Event.Submit]: boolean,
  [Event.UpdateForm]: boolean,
};

class CanvasApiButton extends SceneComponent implements IPainter2d {
  private apiObject: any = {};
  // private functionArguments: Array<any> | null = [];
  private apiCall: Function = async () => {};
  private apiParams: any = {};

  constructor(apiObject: Function | null = null, functionArguments: Array<any> | null = []) {
    super();

    if(apiObject) this.apiObject = apiObject;
    // if(functionArguments) this.functionArguments = functionArguments;
  }

  inputs: Inputs = {
    apiName: null,
    apiParams: [],
    defaultParams: {},
    position: { x: 0, y: 0 },
    size: { h: 100, w: 100 },
    radius: 1,
    font: 'normal bold 80px sans-serif',
    text: 'Placeholder',
    textWidth: 100
  }

  outputs = {
    painter: null,
    value: ""
  } as Outputs;

  events: Events = {
    [Event.Submit]: true,
    [Event.UpdateForm]: true
  };

  onInit() {
    this.outputs.painter = this;

    if(this.inputs.apiName) this.apiCall = this.apiObject[this.inputs.apiName];
    if(this.inputs.defaultParams) this.apiParams = this.inputs.defaultParams;
  }

  async onEvent(eventType: string, eventData: unknown) {
    if (eventType === Event.Submit) {
      const args = this.inputs.apiParams.map(param => this.apiParams[param]);
      console.log("CanvasApiButton", "Submit", "Args", this.apiParams);

      this.apiCall(...args)
      .then((response: any) => {
        console.log("CanvasApiButton", "Submit", "Response", response);
        this.outputs.value = `In ${this.apiParams.year} R2M was number ${response.position} out of ${response.total} (${response.percentile} percentile) amongst ${this.apiParams.euLevel ? "EU" : this.apiParams.country} ${this.apiParams.checkType ? "businesses" : "organizations"}`
        this.notify('response.ready');
      })
      .catch((err: any) => console.error(err));
    }

    if (eventType === Event.UpdateForm) {
      this.apiParams = {...this.apiParams, ...<any>eventData}
    }
  }

  paint(context2d: CanvasRenderingContext2D, size: Size): void {
    context2d.strokeStyle = 'black';
    context2d.fillStyle = 'black';
    context2d.font = this.inputs.font;
    context2d.textAlign = 'left';
    context2d.textBaseline = 'top';
    wrapText(context2d, this.inputs.text, this.inputs.position.x, this.inputs.position.y, this.inputs.textWidth, 30);
  }
}

function wrapText(context: CanvasRenderingContext2D, text: string, x: number, y: number,
  maxWidth: number, lineHeight: number) {

  var words = text.split(' ');
  var line = '';

  for(var n = 0; n < words.length; n++) {
    var testLine = line + words[n] + ' ';
    var metrics = context.measureText(testLine);
    var testWidth = metrics.width;
    if (testWidth > maxWidth && n > 0) {
      context.fillText(line, x, y);
      line = words[n] + ' ';
      y += lineHeight;
    }
    else {
      line = testLine;
    }
  }
  context.fillText(line, x, y);
}

export const canvasApiButtonType = 'mp.canvasApiButton';
export function makeCanvasApiButton(updateFunction: Function | null = null, functionArguments: any[] | null = []) {
  return new CanvasApiButton(updateFunction, functionArguments);
}
